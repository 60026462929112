<template>
  <div
    class="table-item-documents"
    title=" "
    v-on:click="
      (e) => {
        e.stopPropagation();
      }
    ">
    <div
      v-if="props.row.conventionStatut"
      class="table-item-document"
      v-bind:class="
        props.row.convention?.disponible &&
        !props.row.convention?.signee &&
        !props.row.convention?.validee
          ? 'table-item-document-to-sign'
          : ''
      "
      v-on:click="
        () => {
          setIsConventionModalOpen(true);
        }
      ">
      <div class="table-item-document-icon">
        <component
          v-bind:is="
            getDocumentIcon(
              props.row.convention?.disponible,
              props.row.convention?.signee,
              props.row.convention?.validee
            )
          " />
      </div>
      <span class="table-item-document-text">
        Convention(s)&nbsp;: {{ props.row.conventionStatut }}
      </span>
    </div>
    <div
      v-if="props.row.pifStatut"
      class="table-item-document"
      v-on:click="
        () => {
          setIsPIFModalOpen(true);
        }
      ">
      <div class="table-item-document-icon">
        <component
          v-bind:is="
            getDocumentIcon(
              props.row.pif?.disponible,
              props.row.pif?.signee,
              props.row.pif?.validee
            )
          " />
      </div>
      <span class="table-item-document-text">
        PIF(s)&nbsp;: {{ props.row.pifStatut }}
      </span>
    </div>
    <div
      v-if="props.row.certificatLabel"
      class="table-item-document"
      v-on:click="
        () => {
          setIsCertificatModalOpen(true);
        }
      ">
      <div class="table-item-document-icon">
        <component
          v-bind:is="props.row.certificatId ? IconDownload : undefined" />
      </div>
      <span class="table-item-document-text"> Certificat(s) </span>
    </div>
    <div
      v-if="
        !props.row.conventionStatut &&
        !props.row.pifStatut &&
        !props.row.certificatLabel
      "
      class="table-item-document">
      <span class="table-item-document-text !text-refonte_blue-200"> - </span>
    </div>
  </div>
  <refonte-modal-file
    v-bind:show="isConventionModalOpen"
    v-bind:on_close="() => setIsConventionModalOpen(false)"
    title="Convention(s)"
    v-bind:files="conventionsFiles"
    v-bind:file_on_click_download="() => console.log('Téléchargement')" />
  <refonte-modal-file
    v-bind:show="isPIFModalOpen"
    v-bind:on_close="() => setIsPIFModalOpen(false)"
    title="PIF(s)"
    v-bind:files="pifsFiles"
    v-bind:file_on_click_download="() => console.log('Téléchargement')"
    file_signed_label="Signé" />
  <refonte-modal-file
    v-bind:show="isCertificatModalOpen"
    v-bind:on_close="() => setIsCertificatModalOpen(false)"
    title="Certificat(s)"
    v-bind:files="certificatsFiles"
    v-bind:file_on_click_download="() => console.log('Téléchargement')"
    v-bind:file_no_signature_needed="true" />
</template>

<script setup lang="ts">
  import { Inscription } from '@/types/gestion/inscriptions-types';
  import IconUpload from '@/assets/img/refonte/icons/display/icon-upload.svg?component';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteModalFile from '@/components/refonte/modals/refonte-modal-file.vue';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      row: Inscription;
    }>(),
    {}
  );

  const getDocumentIcon = (
    disponible?: boolean,
    signee?: boolean,
    validee?: boolean
  ) => {
    if (disponible && !signee && !validee) {
      return IconUpload;
    }
    if (signee || validee) {
      return IconDownload;
    }
  };

  const isConventionModalOpen = ref<boolean>(false);
  const isPIFModalOpen = ref<boolean>(false);
  const isCertificatModalOpen = ref<boolean>(false);

  function setIsConventionModalOpen(value: boolean) {
    isConventionModalOpen.value = value;
  }
  function setIsPIFModalOpen(value: boolean) {
    isPIFModalOpen.value = value;
  }
  function setIsCertificatModalOpen(value: boolean) {
    isCertificatModalOpen.value = value;
  }

  const conventionsFiles = [
    {
      formation: '25 clés pour un application dynamique des NEP',
      title: 'Convention collective'
    },
    {
      formation: '25 clés pour un application dynamique des NEP',
      title: 'Convention individuelle'
    }
  ];
  const pifsFiles = [
    {
      formation: '25 clés pour un application dynamique des NEP',
      title: 'PIF'
    }
  ];
  const certificatsFiles = [
    {
      formation: '25 clés pour un application dynamique des NEP',
      title: 'Certificat'
    }
  ];
</script>

<style lang="scss" scoped>
  .table-item-documents {
    @apply flex flex-col;
    gap: 6px;

    .table-item-document {
      @apply flex items-center;
      min-height: 20px;
      gap: 2px;

      .table-item-document-icon {
        @apply flex shrink-0 items-center justify-center;
        width: 20px;
        height: 20px;

        :deep(svg) {
          @apply text-refonte_blue-200;
          width: 20px;
          height: 20px;
        }
      }
      .table-item-document-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
      &:hover {
        .table-item-document-text {
          @apply underline;
        }
      }

      &.table-item-document-to-sign {
        .table-item-document-icon {
          :deep(svg) {
            @apply text-refonte-secondary;
          }
        }
        .table-item-document-text {
          @apply text-refonte-secondary;
        }
      }
    }
  }
</style>
