<template>
  <div class="p-[8px]">
    <template v-if="satisfaction.length">
      <refonte-table
        key="satisfaction-1"
        table_index="satisfaction-1"
        v-bind:on_action_click="answerSatisfaction"
        v-bind:on_action_disabled="(row: Satisfaction) => row.repondu"
        on_action_variant="success"
        v-bind:on_action_label="
          (row: Satisfaction) => (row.repondu ? 'Répondu' : 'Répondre')
        "
        v-bind:data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:data_etablissement_width="350"
        v-bind:columns="columns"
        v-bind:data="satisfaction" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { useSatisfactionStore } from '@/stores/satisfaction';
  import { storeToRefs } from 'pinia';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import { Satisfaction } from '@/types/gestion/satisfaction-types';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';

  const answerSatisfaction = (row: Satisfaction) => {};

  const columns = [
    {
      content_index: 'dateQuestionnaire',
      label: 'Date questionnaire',
      width: 140
    },
    {
      content_index: 'organismeNom',
      label: 'Organisme de formation',
      width: 175
    },
    {
      content_index: 'formation',
      label: 'Formation',
      width: 350
    },
    {
      content_index: 'dateReponse',
      label: 'Répondu le',
      width: 110
    },
    {
      content_index: 'reponduPar',
      label: 'Répondu par',
      width: 180
    }
  ];

  const satisfactionStore = useSatisfactionStore();

  const { satisfaction } = storeToRefs(satisfactionStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);
</script>

<style lang="scss" scoped></style>
