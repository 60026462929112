<template>
  <div class="p-[8px]">
    <template v-if="commandes.length">
      <refonte-table
        v-bind:on_details="goToDetails"
        key="commandes-1"
        table_index="commandes-1"
        v-bind:data_etablissement="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientDenomination'
            : undefined
        "
        v-bind:data_etablissement_siret="
          activeEntrepriseId == ALL_ENTREPRISE_FILTER
            ? 'clientSiret'
            : undefined
        "
        v-bind:columns="columns"
        v-bind:data="commandes" />
    </template>
    <refonte-search-results-empty v-else />
  </div>
</template>

<script setup lang="ts">
  import RefonteTable from '@/components/refonte/tables/refonte-table.vue';
  import { Commande } from '@/types/gestion/commandes-types';
  import { useCommandeStore } from '@/stores/commandes';
  import { storeToRefs } from 'pinia';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';
  import {
    ALL_ENTREPRISE_FILTER,
    useEntrepriseStore
  } from '@/stores/entreprise';
  import { useRouter } from 'vue-router';
  import IconValidate20Green from '@/assets/img/refonte/icons/information/icon-validate-20-green.svg?component';
  import IconFailed20Red from '@/assets/img/refonte/icons/information/icon-failed-20-red.svg?component';
  import IconWarning20Orange from '@/assets/img/refonte/icons/information/icon-warning-20-orange.svg?component';
  import { h } from 'vue';
  import CommandesTableItemFormations from '@/views/_espace/_refonte/gestion/_commandes/commandes-table-item-formations.vue';

  const columns = [
    {
      content_index: 'reference',
      label: 'Référence',
      width: 120
    },
    {
      content_index: 'organisateur',
      label: 'Organisme de formation',
      width: 170
    },
    {
      content_index: 'date',
      label: 'Date de commande',
      width: 140
    },
    {
      content_index: 'montantTtc',
      label: 'Montant TTC',
      width: 120
    },
    {
      content_index: 'montantRegle',
      label: 'Montant Réglé',
      width: 120
    },
    {
      content_index: 'reglementStatutSuiviLabel',
      label: 'Règlement',
      width: 90,
      content_icon: (row: Commande) =>
        row.reglementStatutSuiviLabel === 'Oui'
          ? IconValidate20Green
          : row.reglementStatutSuiviLabel === 'Non'
            ? IconFailed20Red
            : IconWarning20Orange
    },
    {
      content_index: 'payeurLabel',
      label: 'Entité Payeur',
      content_sub_text: (row: Commande) =>
        row.payeurSiret && row.payeurSiret !== '-'
          ? row.payeurSiret
          : undefined,
      width: 240
    },
    {
      content_index: 'formation',
      label: 'Formations',
      content_custom: (row: Commande) =>
        h(CommandesTableItemFormations, {
          formations: row.formations
        }),
      width: 360
    },
    {
      content_index: 'bonCommandeLabel',
      label: 'Bon de commande',
      width: 130,
      on_click: (rowIndex: number) => {},
      content_icon: (row: Commande) =>
        row.bonCommandeId ? IconDownload : undefined
    }
  ];

  const commandeStore = useCommandeStore();

  const { commandes } = storeToRefs(commandeStore);

  const entrepriseStore = useEntrepriseStore();

  const { activeEntrepriseId } = storeToRefs(entrepriseStore);

  const router = useRouter();
  const goToDetails = (row: Commande) => {
    router.push(
      `/refonte/gestion/commandes-details?reference=${row.reference}`
    );
  };
</script>

<style lang="scss" scoped></style>
