<template>
  <div
    class="refonte-label"
    v-bind:class="props.class">
    <div
      v-if="props.label_icon"
      class="label-icon">
      <component v-bind:is="props.label_icon" />
    </div>
    <span v-if="props.label">{{ props.label }}</span>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      label?: string;
      label_icon?: Element;
      class?: string;
    }>(),
    {
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-label {
    @apply flex items-center uppercase text-refonte_blue-300;
    height: 20px;
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;

    .label-icon {
      @apply flex shrink-0 items-center justify-center;
      width: 20px;
      height: 20px;

      :deep(svg) {
        @apply text-refonte_blue-200;
        width: 20px;
        height: 20px;
      }
    }
  }
</style>
