<template>
  <refonte-filters
    v-bind:on_delete_click="() => resetFilters()"
    v-bind:show_filters_applied="
      filters_utils.methods.hasValue(filters.interlocuteurId) ||
      filters_utils.methods.hasValue(filters.fonctions) ||
      filters_utils.methods.hasValue(filters.administrateur) ||
      filters_utils.methods.hasValue(filters.droitInscription) ||
      filters_utils.methods.hasValue(filters.accesDocuments) ||
      filters_utils.methods.hasValue(filters.contactId)
    ">
    <template v-slot:default>
      <refonte-filters-item v-if="interlocuteursList.length">
        Interlocuteur
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="interlocuteursList.length"
            v-for="interlocuteur in interlocuteursList"
            v-bind:on_click="
              () =>
                updateFilter(
                  INTERLOCUTEURS_FILTERS_CONFIGURATION.interlocuteurId.name,
                  interlocuteur.id
                )
            "
            v-bind:key="interlocuteur.id">
            {{ interlocuteur.nomPrenom }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-if="fonctionsList.length"
        v-bind:on_open="() => resetFonctions()"
        v-bind:footer_button_on_click="() => updateFonctionsFilter()"
        v-bind:footer_button_disabled="
          !(
            temporaryFonctions &&
            temporaryFonctions.length !==
              (filters.fonctions ? filters.fonctions.length : 0)
          )
        "
        v-bind:number="filters.fonctions?.length ?? undefined">
        Fonction
        <template v-slot:dropdownContent>
          <refonte-checkbox
            v-if="fonctionsList.length"
            v-for="f in fonctionsList"
            v-bind:key="f.id"
            v-bind:label="f.label"
            v-bind:value="
              !!temporaryFonctions?.find((fonction) => fonction === f.id)
            "
            v-on:click="(e: Event) => e.stopPropagation()"
            v-on:input="() => updateFonctions(f.id)" />
        </template>
      </refonte-filters-item>
      <refonte-filters-item>
        Administrateur
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="administrateur in administrateurList"
            v-bind:on_click="
              () =>
                updateFilter(
                  INTERLOCUTEURS_FILTERS_CONFIGURATION.administrateur.name,
                  administrateur
                )
            "
            v-bind:key="administrateur ? 'Oui' : 'Non'">
            {{ administrateur ? 'Oui' : 'Non' }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
      <refonte-filters-item>
        Droit d'inscription
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="droitInscription in droitInscriptionList"
            v-bind:on_click="
              () =>
                updateFilter(
                  INTERLOCUTEURS_FILTERS_CONFIGURATION.droitInscription.name,
                  droitInscription
                )
            "
            v-bind:key="droitInscription ? 'Oui' : 'Non'">
            {{ droitInscription ? 'Oui' : 'Non' }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
      <refonte-filters-item>
        Accès aux documents
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-for="accesDocuments in accesDocumentsList"
            v-bind:on_click="
              () =>
                updateFilter(
                  INTERLOCUTEURS_FILTERS_CONFIGURATION.accesDocuments.name,
                  accesDocuments
                )
            "
            v-bind:key="accesDocuments ? 'Oui' : 'Non'">
            {{ accesDocuments ? 'Oui' : 'Non' }}
          </refonte-dropdown-item>
        </template>
      </refonte-filters-item>
      <refonte-filters-item
        v-if="contactList.length"
        v-bind:on_search="(value: string) => updateContactValue(value)">
        Contact
        <template v-slot:dropdownContent>
          <refonte-dropdown-item
            v-if="contactList.length"
            v-for="contact in contactList"
            v-bind:on_click="
              () =>
                updateFilter(
                  INTERLOCUTEURS_FILTERS_CONFIGURATION.contactId.name,
                  contact.id
                )
            "
            v-bind:key="contact.id">
            <div>
              {{ contact.nomPrenom }}
            </div>
            <div>
              {{ contact.email }}
            </div>
          </refonte-dropdown-item>
          <refonte-search-results-empty v-else />
        </template>
      </refonte-filters-item>
    </template>
    <template v-slot:filtersApplied>
      <template v-for="filterApplied in filtersAppliedConfiguration">
        <refonte-filters-applied-item
          v-bind:key="filterApplied.id"
          v-bind:on_close="
            () =>
              filters_utils.methods.hasValue(filters[filterApplied.id]) &&
              updateFilter(filterApplied.id, undefined)
          "
          v-if="filters_utils.methods.hasValue(filters[filterApplied.id])">
          {{ filterApplied?.value }}
        </refonte-filters-applied-item>
      </template>
    </template>
  </refonte-filters>
</template>
<script setup lang="ts">
  import RefonteFilters from '@/components/refonte/filters/refonte-filters.vue';
  import RefonteFiltersItem from '@/components/refonte/filters/refonte-filters-item.vue';
  import RefonteDropdownItem from '@/components/refonte/dropdowns/refonte-dropdown-item.vue';
  import { computed, onMounted, ref } from 'vue';
  import {
    INTERLOCUTEURS_FILTERS_CONFIGURATION,
    useInterlocuteurFilterStore
  } from '@/stores/interlocuteurs';
  import RefonteFiltersAppliedItem from '@/components/refonte/filters/refonte-filters-applied-item.vue';
  import { storeToRefs } from 'pinia';
  import filters_utils from '@/mixin/utils/filters_utils';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import {
    InterlocuteurMetadata,
    InterlocuteurMetadataContact,
    InterlocuteurMetadataFonction,
    InterlocuteurMetadataInterlocuteur
  } from '@/types/gestion/interlocuteurs-filters-types';
  import { API } from '@/http-common';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteSearchResultsEmpty from '@/components/refonte/inputs/refonte-search-results-empty.vue';

  const contactSearchValue = ref<string>('');
  const updateContactValue = (value: string) => {
    contactSearchValue.value = value;
  };

  const interlocuteurFilterStore = useInterlocuteurFilterStore();
  const { filters } = storeToRefs(interlocuteurFilterStore);

  const getInterlocuteurNomPrenom = (id: number | undefined) => {
    return id
      ? filters_utils.methods.formatFilter(
          'Interlocuteur',
          interlocuteurFilterList.value.find((i) => i.id === id)?.nomPrenom
        )
      : undefined;
  };

  const getContactNomPrenom = (id: number | undefined) => {
    const item = contactFilterList.value.find((i) => i.id == id);
    return item
      ? filters_utils.methods.formatFilter('Contact', item.nomPrenom)
      : '';
  };

  const getFonctionsLabel = (ids: number[] | undefined) => {
    return ids
      ? filters_utils.methods.formatFilter(
          `Fonction${ids.length > 1 ? 's' : ''}`,
          ids
            .map(
              (id) =>
                fonctionFilterList.value.find((type) => type.id === id)?.label
            )
            .join(', ')
        )
      : undefined;
  };

  const updateFilter = (
    name: string,
    value: number | string | undefined | Date | boolean | string[] | number[]
  ) => {
    interlocuteurFilterStore.setFilter(name, value);
  };

  const resetFilters = () => {
    interlocuteurFilterStore.resetFilters();
  };

  const temporaryFonctions = ref<number[] | undefined>(filters.value.fonctions);

  const updateFonctionsFilter = () => {
    updateFilter(
      INTERLOCUTEURS_FILTERS_CONFIGURATION.fonctions.name,
      temporaryFonctions.value && temporaryFonctions.value.length > 0
        ? temporaryFonctions.value
        : undefined
    );
  };

  const resetFonctions = () => {
    temporaryFonctions.value = filters.value.fonctions;
  };

  const updateFonctions = (value: number) => {
    if (temporaryFonctions.value?.find((f) => f === value)) {
      temporaryFonctions.value = temporaryFonctions.value.filter(
        (f) => f !== value
      );
    } else {
      temporaryFonctions.value = temporaryFonctions.value
        ? [...temporaryFonctions.value, value]
        : [value];
    }
  };

  const filtersAppliedConfiguration = computed(() => [
    {
      id: INTERLOCUTEURS_FILTERS_CONFIGURATION.interlocuteurId.name,
      value: getInterlocuteurNomPrenom(filters.value.interlocuteurId)
    },
    {
      id: INTERLOCUTEURS_FILTERS_CONFIGURATION.fonctions.name,
      value: getFonctionsLabel(filters.value.fonctions)
    },
    {
      id: INTERLOCUTEURS_FILTERS_CONFIGURATION.administrateur.name,
      value: filters_utils.methods.hasValue(filters.value.administrateur)
        ? filters_utils.methods.formatFilter(
            'Administrateur',
            filters.value.administrateur ? 'Oui' : 'Non'
          )
        : undefined
    },
    {
      id: INTERLOCUTEURS_FILTERS_CONFIGURATION.droitInscription.name,
      value: filters_utils.methods.hasValue(filters.value.droitInscription)
        ? filters_utils.methods.formatFilter(
            "Droit d'inscription",
            filters.value.droitInscription ? 'Oui' : 'Non'
          )
        : undefined
    },
    {
      id: INTERLOCUTEURS_FILTERS_CONFIGURATION.accesDocuments.name,
      value: filters_utils.methods.hasValue(filters.value.accesDocuments)
        ? filters_utils.methods.formatFilter(
            'Accès aux documents',
            filters.value.accesDocuments ? 'Oui' : 'Non'
          )
        : undefined
    },
    {
      id: INTERLOCUTEURS_FILTERS_CONFIGURATION.contactId.name,
      value: getContactNomPrenom(filters.value.contactId)
    }
  ]);

  const interlocuteurFilterList = ref<InterlocuteurMetadataInterlocuteur[]>([]);

  const interlocuteursList = computed(() => interlocuteurFilterList.value);

  const fonctionFilterList = ref<InterlocuteurMetadataFonction[]>([]);

  const fonctionsList = computed(() => fonctionFilterList.value);

  const administrateurFilterList = ref<boolean[]>([true, false]);

  const administrateurList = computed(() => administrateurFilterList.value);

  const droitInscriptionFilterList = ref<boolean[]>([true, false]);

  const droitInscriptionList = computed(() => droitInscriptionFilterList.value);

  const accesDocumentsFilterList = ref<boolean[]>([true, false]);

  const accesDocumentsList = computed(() => accesDocumentsFilterList.value);

  const contactFilterList = ref<InterlocuteurMetadataContact[]>([]);

  const contactList = computed(() =>
    contactFilterList.value.filter(
      (c) =>
        contactSearchValue.value.length < 3 ||
        c.nomPrenom
          .toLowerCase()
          .includes(contactSearchValue.value.toLowerCase())
    )
  );

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);

  const fetchMetadata = async () => {
    const response = await API.get(
      `client/v2/interlocuteurs/metadata?clientIds=${activeClientIds.value}`
    );

    if (response && response.data) {
      const data: InterlocuteurMetadata = response.data;
      fonctionFilterList.value = data.fonctions ?? [];
      interlocuteurFilterList.value = data.interlocuteurs ?? [];
      contactFilterList.value = data.contacts ?? [];
    }
  };

  onMounted(async () => {
    resetFilters();
    await fetchMetadata();
  });
</script>

<style lang="scss" scoped></style>
