<template>
  <template v-if="!loading">
    <refonte-card>
      <refonte-filters-tabs>
        <template v-slot:default>
          <refonte-filters-tabs-item
            label="Inscriptions"
            v-bind:selected="activeTab === 'inscriptions'"
            v-bind:on_click="
              () => {
                setActiveTab('inscriptions');
              }
            " />
          <refonte-filters-tabs-item
            label="Inscriptions annulées/transférées"
            v-bind:selected="activeTab === 'inscriptions-annulees'"
            v-bind:on_click="
              () => {
                setActiveTab('inscriptions-annulees');
              }
            " />
        </template>
      </refonte-filters-tabs>
      <inscriptions-filters />
    </refonte-card>
    <refonte-card class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-table-number
          v-bind:number="inscriptions.length"
          v-bind:label="
            inscriptions.length > 1 ? 'Inscriptions' : 'Inscription'
          " />
        <IconSeparatorVertical />
        <refonte-table-number
          v-bind:number="nombreConventionsASigner"
          v-bind:label="`${
            nombreConventionsASigner > 1 ? 'Conventions' : 'Convention'
          } à signer`"
          v-bind:on_click="
            () => {
              inscriptionFilterStore.setFilter(
                INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                'CONVENTIONS_A_SIGNER'
              );
            }
          " />
        <IconSeparatorVertical />
        <refonte-table-number
          v-bind:number="nombrePifsASigner"
          label="PIF à signer"
          v-bind:on_click="
            () => {
              inscriptionFilterStore.setFilter(
                INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                'PIF_A_SIGNER'
              );
            }
          " />
        <IconSeparatorVertical />
        <refonte-table-number
          v-bind:number="nombreTestsPositionnementNonRealise"
          v-bind:label="`${
            nombreTestsPositionnementNonRealise > 1 ? 'Tests' : 'Test'
          } de positionnement non ${
            nombreTestsPositionnementNonRealise > 1 ? 'réalisés' : 'réalisé'
          }`"
          v-bind:on_click="
            () => {
              inscriptionFilterStore.setFilter(
                INSCRIPTIONS_FILTERS_CONFIGURATION.action.name,
                'T_POS_NON_REALISE'
              );
            }
          " />
        <div class="flex flex-grow items-center justify-end">
          <refonte-button v-bind:icon_left="IconExport">
            Exporter
          </refonte-button>
        </div>
      </div>
      <refonte-separator class="px-[12px]" />
      <inscriptions-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { computed, onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import InscriptionsContent from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-content.vue';
  import {
    INSCRIPTIONS_FILTERS_CONFIGURATION,
    useInscriptionFilterStore,
    useInscriptionStore
  } from '@/stores/inscriptions';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import InscriptionsFilters from '@/views/_espace/_refonte/gestion/_inscriptions/inscriptions-filters.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import { Inscription } from '@/types/gestion/inscriptions-types';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import IconExport from '@/assets/img/refonte/icons/action/icon-export.svg?component';
  import RefonteFiltersTabs from '@/components/refonte/filters/refonte-filters-tabs.vue';
  import RefonteFiltersTabsItem from '@/components/refonte/filters/refonte-filters-tabs-item.vue';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const inscriptionFilterStore = useInscriptionFilterStore();

  const { filters } = storeToRefs(inscriptionFilterStore);

  const dataLoading = ref<boolean>(false);
  const inscriptionStore = useInscriptionStore();
  const { inscriptions } = storeToRefs(inscriptionStore);

  const nombreConventionsASigner = computed(
    () =>
      inscriptions.value.filter(
        (i: Inscription) =>
          i.conventionStatut && i.conventionStatut === 'A signer'
      ).length
  );

  const nombrePifsASigner = computed(
    () =>
      inscriptions.value.filter((i: Inscription) => i.pifStatut === 'A signer')
        .length
  );

  const nombreTestsPositionnementNonRealise = computed(
    () =>
      inscriptions.value.filter(
        (i: Inscription) => i.testPositionnementStatut === 'Non réalisé'
      ).length
  );

  const fetchData = async () => {
    dataLoading.value = true;
    await inscriptionStore.fetchInscriptions(route.query);
    dataLoading.value = false;
  };

  watch(activeClientIds, () => {
    fetchData();
  });

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      await fetchData();
    }
  );

  const initFilters = () => {
    inscriptionFilterStore.initFiltersFromQuery(route.query);
  };

  onMounted(() => {
    initFilters();
    fetchData();
  });

  const router = useRouter();
  watch(filters, async () => {
    let query = api_utils.methods.formatParams(filters.value, [
      INSCRIPTIONS_FILTERS_CONFIGURATION.date.name,
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      INSCRIPTIONS_FILTERS_CONFIGURATION.datePeriodeFin.name
    ]);

    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({
      query: {
        ...(route.query.clientIds && { clientIds: route.query.clientIds }),
        ...query
      }
    });
  });

  const activeTab = ref<string>('inscriptions');

  const setActiveTab = (tab: string) => {
    activeTab.value = tab;
  };
</script>

<style lang="scss" scoped></style>
