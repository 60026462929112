<template>
  <div class="table-item-formations">
    <template
      v-if="props.formations && props.formations.length > 0"
      v-for="(formation, index) in props.formations"
      v-bind:key="formation.id">
      <div
        v-if="isAllFormationsVisible || (!isAllFormationsVisible && index < 1)"
        class="table-item-formation">
        <span class="table-item-formation-text">{{ formation.titre }}</span>
        <span class="table-item-formation-sub-text">{{
          buildDate(formation.dateDebut, formation.dateFin)
        }}</span>
      </div>
    </template>
    <div
      v-else
      class="table-item-formation">
      <span class="table-item-formation-text !text-refonte_blue-200"> - </span>
    </div>
    <div
      v-if="props.formations && props.formations.length > 1"
      class="table-item-formation"
      v-on:click="() => setIsAllFormationsVisible(!isAllFormationsVisible)">
      <span class="table-item-formation-link">
        {{ isAllFormationsVisible ? 'Voir moins' : 'Voir plus' }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Formation } from '@/types/gestion/commandes-types';
  import { ref } from 'vue';

  const props = withDefaults(
    defineProps<{
      formations: Formation[];
    }>(),
    {}
  );

  const isAllFormationsVisible = ref<boolean>(false);

  function setIsAllFormationsVisible(value: boolean) {
    isAllFormationsVisible.value = value;
  }

  const buildDate = (dateDebut: string, dateFin: string): string => {
    if (dateDebut === dateFin) {
      return dateDebut;
    } else {
      return `${dateDebut} - ${dateFin}`;
    }
  };
</script>

<style lang="scss" scoped>
  .table-item-formations {
    @apply flex flex-col;
    gap: 6px;

    .table-item-formation {
      @apply inline-flex flex-col items-start;
      min-height: 20px;
      gap: 2px;

      .table-item-formation-text {
        @apply flex-grow text-refonte-black;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }
      .table-item-formation-sub-text {
        @apply text-refonte-black-50;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
      }
      .table-item-formation-link {
        @apply flex-grow;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;

        &:hover {
          @apply cursor-pointer underline;
        }
      }
    }
  }
</style>
