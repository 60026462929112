import { API } from '@/http-common.js';
import { Filters } from '@/types/gestion/commandes-filters-types';
import { Commande, CommandeResponse } from '@/types/gestion/commandes-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    references: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    vendeurId: undefined,
    commandeReglee: undefined,
    contactId: undefined,
    formationId: undefined,
    dateFormation: undefined,
    dateFormationPeriodeId: undefined,
    dateFormationPeriodeDebut: undefined,
    dateFormationPeriodeFin: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    references: undefined,
    date: undefined,
    datePeriodeId: undefined,
    datePeriodeDebut: undefined,
    datePeriodeFin: undefined,
    vendeurId: undefined,
    commandeReglee: undefined,
    contactId: undefined,
    formationId: undefined,
    dateFormation: undefined,
    dateFormationPeriodeId: undefined,
    dateFormationPeriodeDebut: undefined,
    dateFormationPeriodeFin: undefined
};

export const COMMANDES_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    references: {
        name: 'references',
        isArray: true
    },
    date: {
        name: 'date',
        isArray: false
    },
    datePeriodeId: {
        name: 'datePeriodeId',
        isArray: false
    },
    datePeriodeDebut: {
        name: 'datePeriodeDebut',
        isArray: false
    },
    datePeriodeFin: {
        name: 'datePeriodeFin',
        isArray: false
    },
    vendeurId: {
        name: 'vendeurId',
        isArray: false
    },
    commandeReglee: {
        name: 'commandeReglee',
        isArray: false
    },
    contactId: {
        name: 'contactId',
        isArray: false
    },
    formationId: {
        name: 'formationId',
        isArray: false
    },
    dateFormation: {
        name: 'dateFormation',
        isArray: false
    },
    dateFormationPeriodeId: {
        name: 'dateFormationPeriodeId',
        isArray: false
    },
    dateFormationPeriodeDebut: {
        name: 'dateFormationPeriodeDebut',
        isArray: false
    },
    dateFormationPeriodeFin: {
        name: 'dateFormationPeriodeFin',
        isArray: false
    }
};

export const useCommandeFilterStore = defineStore('commandes-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = COMMANDES_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
            if (
                [
                    COMMANDES_FILTERS_CONFIGURATION.date.name,
                    COMMANDES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
                    COMMANDES_FILTERS_CONFIGURATION.datePeriodeFin.name,
                    COMMANDES_FILTERS_CONFIGURATION.dateFormation.name,
                    COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut
                        .name,
                    COMMANDES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name
                ].includes(key)
            ) {
                newPayload[key] = new Date(newPayload[key]);
            }
            if (
                [COMMANDES_FILTERS_CONFIGURATION.commandeReglee.name].includes(
                    key
                )
            ) {
                newPayload[key] = newPayload[key] === 'true';
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useCommandeStore = defineStore('commandes', () => {
    const commandes = ref<Commande[]>([]);

    const fetchCommandes = async (query: Filters) => {
        const response = await API.get(`client/v2/commandes`, {
            params: query
        });
        if (response?.data) {
            commandes.value = response?.data.map((c: CommandeResponse) =>
                mapCommande(c)
            );
        }
    };

    const mapCommande = (c: CommandeResponse): Commande => {
        return {
            ...c,
            clientDenomination: c.client.denomination,
            clientSiret: c.client.siret ?? undefined,
            organisateur: c.vendeur.nom,
            montantTtc: buildMontant(c.montantTtc),
            montantRegle: buildMontant(c.montantRegle),
            payeurLabel: c.payeur.denomination,
            payeurSiret: c.payeur.siret ? `SIRET ${c.payeur.siret}` : undefined,
            reglementStatutSuiviLabel: c.reglementStatutSuivi.label,
            ...(c.bonCommandeId && {
                bonCommandeLabel: 'Télécharger'
            })
        };
    };

    const buildMontant = (montant: number): string => {
        const formatter = new Intl.NumberFormat('fr-FR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        });
        return formatter.format(montant);
    };

    return {
        commandes,
        fetchCommandes
    };
});
