import { API } from '@/http-common.js';
import { Filters } from '@/types/gestion/annuaire-filters-types';
import { Annuaire, AnnuaireResponse } from '@/types/gestion/annuaire-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LocationQuery } from 'vue-router';

const noFilterState: Filters = {
    clientIds: [],
    contactId: undefined
};

const filterInitialState: Filters = {
    clientIds: [],
    contactId: undefined
};

export const ANNUAIRE_FILTERS_CONFIGURATION = {
    clientIds: {
        name: 'clientIds',
        isArray: true
    },
    contactId: {
        name: 'contactId',
        isArray: false
    }
};

export const useAnnuaireFilterStore = defineStore('annuaire-filter', () => {
    const filters = ref<Filters>(filterInitialState);

    const setFilter = (name: string, value: any) => {
        filters.value = { ...filters.value, [name]: value };
    };
    const resetFilters = () => {
        filters.value = noFilterState;
    };

    const parsePayload = (payload: LocationQuery): Filters => {
        const newPayload = { ...payload };

        Object.keys(newPayload).forEach((key) => {
            const filter = ANNUAIRE_FILTERS_CONFIGURATION[key] as any;

            if (filter && filter.isArray) {
                newPayload[key] = newPayload[key].split(',');
            }
        });

        return newPayload;
    };

    const initFiltersFromQuery = (payload: LocationQuery) => {
        const parsedPayload = parsePayload(payload) as Filters;
        if (payload && Object.keys(payload).length > 0) {
            filters.value = {
                ...noFilterState,
                ...parsedPayload
            };
        } else {
            filters.value = {
                ...filterInitialState,
                ...parsedPayload
            };
        }
    };

    return {
        resetFilters,
        setFilter,
        filters,
        initFiltersFromQuery
    };
});

export const useAnnuaireStore = defineStore('annuaire', () => {
    const annuaire = ref<Annuaire[]>([]);

    const fetchAnnuaire = async (query: Filters) => {
        const response = await API.get(`client/v2/annuaires`, {
            params: query
        });
        if (response?.data) {
            annuaire.value = response?.data.map((a: AnnuaireResponse) =>
                mapAnnuaire(a)
            );
        }
    };

    const mapAnnuaire = (a: AnnuaireResponse): Annuaire => {
        return {
            ...a,
            clientDenomination: a.client.denomination,
            clientSiret: a.client.siret ?? undefined,
            contactNom: a.contact.nom,
            contactPrenom: a.contact.prenom,
            contactNomPrenom: `${a.contact.nom} ${a.contact.prenom}`,
            compteJiniusLabel: a.compteJinius ? 'Oui' : 'Non',
            interlocuteurLabel: a.interlocuteur ? 'Oui' : 'Non',
            profilLabel: a.profil ? a.profil.label : undefined
        };
    };

    return {
        annuaire,
        fetchAnnuaire
    };
});
