<template>
  <div
    class="refonte-details-header"
    v-bind:class="props.class">
    <div class="details-header-title-section">
      <div class="details-header-title-section-left">
        <div
          v-if="detail.statut"
          class="details-header-title-section-left-tags">
          <refonte-tag v-bind:variant="buildTagStatut()">
            {{ detail.statut }}
          </refonte-tag>
        </div>
        <h1>
          {{ `Commande ${detail.commandeReference} - ${detail.montantHT}€ HT` }}
        </h1>
      </div>
      <div class="details-header-title-section-right">
        <refonte-button
          variant="secondary"
          v-bind:icon_left="IconDownload">
          Bon de commande
        </refonte-button>
      </div>
    </div>
    <div class="details-header-infos-section">
      <refonte-card class="details-header-infos-section-left">
        <refonte-details-header-item
          v-bind:icon_left="IconParticipants"
          v-bind:text="`Participants : ${detail.nbParticipants}`" />
        <refonte-details-header-item
          v-bind:icon_left="IconCommandes"
          v-bind:text="`Commandé par ${detail.commandeur}`" />
      </refonte-card>
      <refonte-card class="details-header-infos-section-right">
        <refonte-details-header-item
          v-bind:icon_left="IconFactures"
          v-bind:text="`Vendu par ${detail.vendeur.nom}`" />
        <refonte-details-header-item
          v-bind:icon_left="IconFactures"
          v-bind:text="`Payeur : ${detail.payeur.denomination}`" />
      </refonte-card>
    </div>
  </div>
</template>

<script setup lang="ts">
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconParticipants from '@/assets/img/refonte/icons/menu/icon-trainees.svg?component';
  import IconCommandes from '@/assets/img/refonte/icons/menu/icon-orders.svg?component';
  import IconFactures from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteDetailsHeaderItem from '@/components/refonte/details/refonte-details-header-item.vue';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteTag from '@/components/refonte/tags/refonte-tag.vue';

  const props = withDefaults(
    defineProps<{
      detail: any;
      class?: string;
    }>(),
    {
      class: ''
    }
  );

  const buildTagStatut = () => {
    if (props.detail.statut === 'Réglée') {
      return 'success';
    } else if (props.detail.statut === 'Non réglée') {
      return 'danger';
    } else if (props.detail.statut === 'Partiellement réglée') {
      return 'warning';
    }
  };
</script>

<style lang="scss" scoped>
  .refonte-details-header {
    @apply flex flex-col items-start self-stretch;
    gap: 12px;

    .details-header-title-section {
      @apply flex flex-wrap items-center justify-end self-stretch;
      color: #000;
      gap: 16px;
      padding: 0 12px;

      .details-header-title-section-left {
        @apply flex flex-grow flex-col items-start justify-center self-stretch;
        gap: 2px;

        .details-header-title-section-left-tags {
          @apply flex flex-wrap items-center;
          gap: 2px;
        }
      }
    }

    .details-header-infos-section {
      @apply flex items-stretch self-stretch;
      gap: 12px;

      .details-header-infos-section-left,
      .details-header-infos-section-right {
        @apply flex flex-row flex-wrap items-start justify-items-start gap-0;
        flex: 1 0 0;
      }
    }
  }
</style>
