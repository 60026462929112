<template>
  <refonte-dropdown
    v-bind:on_search="props.on_search"
    v-bind:on_open="props.on_open"
    v-bind:footer_button_label="props.footer_button_label"
    v-bind:footer_button_on_click="props.footer_button_on_click"
    v-bind:footer_button_disabled="props.footer_button_disabled">
    <button
      class="refonte-filters-item"
      v-bind:class="`${props.number ? 'filters-item-with-number' : ''} ${props.class}`"
      type="button">
      <span
        v-if="props.number"
        class="filters-item-number">
        {{ props.number }}
      </span>
      <span class="filters-item-text">
        <slot></slot>
      </span>
      <IconChevronDown />
    </button>
    <template v-slot:dropdownContent>
      <slot name="dropdownContent"></slot>
    </template>
  </refonte-dropdown>
</template>

<script setup lang="ts">
  import IconChevronDown from '@/assets/img/refonte/icons/display/icon-chevron-down.svg?component';
  import RefonteDropdown from '@/components/refonte/dropdowns/refonte-dropdown.vue';

  const props = withDefaults(
    defineProps<{
      number?: number;
      on_search?: Function;
      on_open?: Function;
      footer_button_label?: string;
      footer_button_on_click?: Function;
      footer_button_disabled?: boolean;
      class?: string;
    }>(),
    {
      footer_button_label: 'Appliquer',
      footer_button_disabled: false,
      class: ''
    }
  );
</script>

<style lang="scss" scoped>
  .refonte-filters-item {
    @apply inline-flex cursor-pointer items-center justify-center bg-transparent text-refonte_blue-600;
    gap: 4px;
    border-radius: 4px;
    padding: 9px 8px 9px 10px;

    .filters-item-number {
      @apply flex items-center justify-center bg-refonte_blue-400 text-center text-refonte-white;
      min-width: 16px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      font-size: 10px;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
    }
    :deep(svg) {
      @apply text-refonte_blue-600;
      min-width: 16px;
      width: 16px;
      height: 16px;
    }
    .filters-item-text {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }

    &.filters-item-with-number {
      @apply bg-refonte-pale-violet-2;
    }
    &:hover {
      @apply bg-refonte-pale-violet-2;
    }
    &:active,
    &:focus {
      @apply bg-refonte-pale-violet-3;
    }
  }
</style>
